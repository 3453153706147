import React, { useState, useCallback } from 'react';
import { StyleSheet, Text, View, Button } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';

import BazerService from '../api/bazer/BazerService.js';
import StorageService from '../service/StorageService.js';
import AuthService from '../api/auth/AuthService.js';

export const SettingScreen = ({ seedPhrase, navigation }) => {
    const [email, setEmail] = useState(null);
    const [telegramId, setTelegramId] = useState(null);

    useFocusEffect(
        useCallback(() => {
            const fetchUserInfo = async () => {
                try {
                    const response = await BazerService.UserInfo(seedPhrase);
                    setEmail(response.data.user.email);
                    setTelegramId(response.data.user.id_telegram);
                } catch (error) {
                    console.error('Error getting email and Telegram ID:', error);
                }
            };

            fetchUserInfo();
        }, [seedPhrase])
    );
    const logout = async () => {
        try {
            await Promise.all([
                AuthService.logout(),
                StorageService.deleteData('userId'),
            ]);
            navigation.replace("Auth"); // Перенаправляем на экран авторизации
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };
    return (
        <View style={styles.container}>
            <View style={styles.section}>
                <Text style={styles.sectionTitle}>Account Management</Text>
            </View>
            <View style={styles.rightContent}>
                {email && <Text style={styles.info}>Email: {email}</Text>}
                {telegramId && <Text style={styles.info}>Telegram ID: {telegramId}</Text>}
                <View style={styles.buttonContainer}>
                    <Button title="Logout" onPress={logout} />
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 20,
        backgroundColor: '#fff',
    },
    section: {
        alignItems: 'center',
    },
    sectionTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 10,
        textAlign: 'center',
    },
    rightContent: {
        alignItems: 'flex-start',
    },
    info: {
        fontSize: 16,
        marginTop: 10,
        textAlign: 'left',
    },
    buttonContainer: {
        marginTop: 10,
    },
});
