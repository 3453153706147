class AuthService {
    constructor() {
        this.apiUrl = "https://auth.bazerwallet.com";
    }

    async Login(email, password) {
        try {
            const body = {
                email,
                password,
            };
            const response = await fetch(`${this.apiUrl}/auth/login`, {
                method: 'POST',
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body),
            });

            const data = await response.json();
            return this.apiResponse(data);
        } catch (error) {
            console.log("AuthService.Login: ", error);
            return { status: "error", message: error.message };
        }
    }

    async verify() {
        try {
            const response = await fetch(`${this.apiUrl}/auth/verify`, {
                method: 'POST',
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const data = await response.json();
            return this.apiResponse(data);
        } catch (error) {
            console.log("AuthService.verify: ", error);
            return { status: "error", message: error.message };
        }
    }

    async logout() {
        try {
            const response = await fetch(`${this.apiUrl}/auth/logout`, {
                method: 'POST',
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const data = await response.json();
            return this.apiResponse(data);
        } catch (error) {
            console.log("AuthService.logout: ", error);
            return { status: "error", message: error.message };
        }
    }

    async updateToken() {
        try {
            const response = await fetch(`${this.apiUrl}/auth/updateToken`, {
                method: 'POST',
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const data = await response.json();
            return this.apiResponse(data);
        } catch (error) {
            console.log("AuthService.updateToken: ", error);
            return { status: "error", message: error.message };
        }
    }

    apiResponse(response) {
        return {
            status: response.status,
            data: response.data,
            error: response.error
        };
    }
}

export default new AuthService();